import React, { FC } from 'react';

import Links from '../../../components/pagesComponents/CaseStudy/Links';
import FreshiiLayouts from '../../../components/PagesLayouts/FreshiiLayouts';
import Card from '../../../components/pagesComponents/CaseStudy/Card';
import FixedButton from '../../../components/common/FixedButton';

import flyer1 from '../../../assets/img/CaseStudy/Freshii/freshii-featured.png';
import flyer4 from '../../../assets/img/CaseStudy/Freshii/freshii-main.png';

const ProjectGallery: FC = () => {
    return (
        <FreshiiLayouts>
            <div className="scope-of-project__cards">
                <div className="scope-of-project__cards-wrapper scope-of-project__cards-wrapper--mb">
                    <Card img={flyer1} size="freshii-xl" imageType="freshii-xl" />
                </div>
                <div className="scope-of-project__cards-wrapper scope-of-project__cards-wrapper--mb-60">
                    <Card img={flyer4} size="freshii-xl" imageType="freshii-xl" />
                </div>
            </div>
            <div className="scope-of-project__body scope-of-project__body--mw">
                <Links />
            </div>
            <FixedButton/>
        </FreshiiLayouts>
    );
};

export default ProjectGallery;
